import axios from "axios";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../API/Endpoints";
import { AuthContext } from "../Contexts/Auth";
import { ThemeContext } from "../Contexts/Theme";
import { UserContext } from "../Contexts/User";
import api from "../Services/api";
import { cleanTaxIDMask, createHeaders, loadItem } from "../Utils";
import { useToast } from "./toast";
import { useSessionTimer } from "./useSessionTimer";
import { useValidateErrorResponse } from "./useValidateErrorResponse";

const { CancelToken } = axios;

export const useLogin = () => {
  let history = useHistory();
  const theme = loadItem("Options");
  const { addToast } = useToast();
  const [, dispatchAuthContext] = React.useContext(AuthContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState({});
  const [requestData, setRequestData] = React.useState({});

  const { handleSetTimer } = useSessionTimer();

  const { username, password, last_number, tokenApp } = requestData;

  useEffect(() => {
    if (username && password && last_number) {
      dispatchAuthContext({
        type: "setFirstStepData",
        firstStepData: {
          username,
          password,
          last_number,
        },
      });
    }
  }, [requestData, dispatchAuthContext]);

  useEffect(() => {
    if (tokenApp) {
      api.defaults.headers.Authorization = `Bearer ${tokenApp}`;

      sessionStorage.setItem("token", tokenApp);

      dispatchAuthContext({
        type: "setApplicationToken",
        token: tokenApp,
      });
      if (history?.location?.pathname === "/step2") {
        history.push("/");
      }
    }
  }, [tokenApp, dispatchAuthContext]);

  const fetchLogin = useCallback(
    async ({ username, password, captchaToken, type }) => {
      // if (!captchaToken) {
      //   addToast({
      //     title: "O Google Recaptcha não conseguiu validar sua sessão.",
      //     description: "Por favor recarregue a página ou tente novamente",
      //     type: "error",
      //   });
      //   return;
      // }

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      if (!requestLoading && username && password) {
        setRequestError({});
        setRequestLoading(true);

        //REMOVE PONTOS E TRACOS DO CPF/CNPJ
        //username = cleanTaxIDMask(username);

        return new Promise((resolve, reject) => {
          let body;
          !!type
            ? (body = {
                username,
                password,
                type,
                // captchaToken,
              })
            : (body = {
                username,
                password,
                // captchaToken,
              });

          api
            .post(Endpoints.POST.login, body, {
              cancelToken,
              headers: {
                "branch-id": theme?.branch_id,
              },
            })
            .then(({ data }) => {
              setRequestLoading(false);
              setRequestData({
                ...requestData,
                username: username,
                password: password,
                last_number: data.phone_number,
              });
            })
            .catch((err) => {
              const errMessage = err?.response?.data?.description;
              if (errMessage) {
                addToast({
                  title: err.response.data.information,
                  description: err.response.data.description,
                  type: err.response.data.isError ? "error" : "warning",
                });
                setRequestError(errMessage);
                reject(errMessage);
              }
            })
            .finally(() => {
              setRequestLoading(false);
            });
        });
      }
    },
    [requestData, requestError]
  );
  const fetchValidateTwoStepToken = useCallback(
    ({ username, password, code }) => {
      if (!requestLoading && username && password && code) {
        setRequestError(false);
        setRequestLoading(true);
        const axiosToken = CancelToken.source();
        const cancelToken = axiosToken?.token;

        return new Promise((resolve, reject) => {
          const body = {
            username,
            password,
            code,
          };

          api
            .post(Endpoints.POST.smsToken, body, {
              cancelToken,
              headers: {
                "branch-id": theme?.branch_id,
              },
            })
            .then(({ data }) => {
              handleSetTimer(data.expires_in);
              setRequestData({
                ...requestData,
                token: data.token_id,
                tokenApp: data.access_token,
              });
              if (data["closing-balance"]) {
                sessionStorage.setItem("@PortalBC:isClosingBalanceShow", true);
              }
            })
            .catch((err) => {
              setRequestError(true);
              const errMessage = validateError(err?.response?.data);
              addToast({
                title: err.response.data.information,
                description: err.response.data.description,
                type: err.response.data.isError ? "error" : "warning",
              });
              reject(errMessage);
            })
            .finally(() => {
              setRequestLoading(false);
            });
        });
      }
    },
    [requestData]
  );

  return {
    requestError,
    requestLoading,
    fetchLogin,
    fetchValidateTwoStepToken,
  };
};

export const useRecoveryPassword = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({ status: false });

  const fetchRecoveryPassword = ({ username }) => {
    return new Promise((resolve, reject) => {
      if (!requestLoading && username) {
        setRequestError(false);
        setRequestLoading(true);
        const axiosToken = CancelToken.source();
        const cancelToken = axiosToken?.token;

        const body = {
          email: username,
        };

        api
          .put(Endpoints.PUT.reset_password, body, { cancelToken })
          .then(() => {
            setRequestData({ status: true });
            resolve();
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      } else {
        reject();
      }
    });
  };

  return { requestData, requestError, requestLoading, fetchRecoveryPassword };
};

export const useChangePassword = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({ status: false });

  const fetchChangePassword = ({ password, token }) => {
    return new Promise((resolve, reject) => {
      if (!requestLoading && password && token) {
        setRequestError(false);
        setRequestLoading(true);
        const axiosToken = CancelToken.source();
        const cancelToken = axiosToken?.token;

        const body = {
          password,
          token,
        };

        api
          .put(Endpoints.PUT.new_password, body, {
            cancelToken,
          })
          .then(() => {
            setRequestData({ status: true });
            resolve();
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      } else {
        reject();
      }
    });
  };

  return { requestData, requestError, requestLoading, fetchChangePassword };
};

export const useLogout = () => {
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const [, dispatchUserContext] = useContext(UserContext);
  const [, dispatchThemeContext] = useContext(ThemeContext);

  const [requestLoading, setRequestLoading] = React.useState(false);

  const fetchLogout = () => {
    if (stateAuthContext.tokenApp) {
      return new Promise((resolve, reject) => {
        if (!requestLoading) {
          setRequestLoading(true);
          const token = loadItem("token");
          const headers = createHeaders();

          const body = {
            token,
          };

          api.put(Endpoints.PUT.logout, body, { headers }).finally(() => {
            dispatchAuthContext({ type: "clear" });
            dispatchUserContext({ type: "clear" });
            dispatchThemeContext({ type: "clear" });
            resolve();
          });
        } else {
          reject();
        }
      });
    }
  };

  return { fetchLogout };
};
