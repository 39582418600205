import {
  ReceiptList,
  RecurrentContact,
  ScreenGreeting,
} from "../../../Components/Molecules";
import { LinkButton } from "../../../Components/Atoms";
import {
  Container,
  PixActions,
  PixContainer,
  PixTransactions,
  LinkContent,
} from "./styles";

import { useTransactionsData } from "../../../Hooks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { QrCode, BarChart, Payment, ContentCopy } from "@mui/icons-material";
import { ReturnPixModal } from "../../../Components/Molecules/ReturnPixModal";
import { ModalLoader } from "../../../Components/Atoms/ModalLoader";

export const PixArea = () => {
  const qrCode = sessionStorage.getItem("@BCBankline:Qrcode");
  const [returnPixModal, setReturnPixModal] = useState(false);
  const {
    state: transactionsData,
    handleGET: fetchTransactionsGET,
    loading: transactionsLoading,
  } = useTransactionsData();

  useEffect(() => {
    fetchTransactionsGET({ limit: 5, product: "pix" });
    //CHAMA LISTA DE DEVOLUÇÔES
  }, []);

  return (
    <Container>
      <ModalLoader
        loading={transactionsLoading.GET}
        text={"Carregando informações"}
      />
      <PixContainer>
        <div>
          <ScreenGreeting
            title={"Minha área Pix"}
            subTitle={
              "Aqui você poderá realizar tudo o que for necessário com o PIX"
            }
          />
        </div>
        {/* <div>
          <img src={qrCode} style={{ width: 180, height: 180 }}></img>
        </div> */}
        {/* <RecurrentContact
          refreshLoading={(value) => setLoading(value)}
          recurrentContacts={recurrentContacts}
          transferType={"pix"}
        /> */}
      </PixContainer>

      <PixActions>
        <LinkContent>
          {/* <LinkButton text="Meus limites"></LinkButton> */}
          <LinkButton path={"/pix/copia-cola"} icon={<ContentCopy />}>
            Pix copia e cola
          </LinkButton>
          <LinkButton path={"pix/transferencia"} icon={<Payment />}>
            Transferir
          </LinkButton>
          <LinkButton path={"/pix/vendas"} icon={<QrCode />}>
            Gerar cobrança
          </LinkButton>
          <LinkButton path={"/pix/detalhes"} icon={<BarChart />}>
            Detalhes PIX
          </LinkButton>
          {/* <LinkButton path={"/teste"} icon={<Receipt />}>
            Comprovantes
          </LinkButton> */}

          {/* <LinkButton
            onClick={() => {
              setReturnPixModal(true);
            }}
            icon={<ArrowBack />}
          >
            Devolver Pix
          </LinkButton> */}
        </LinkContent>
      </PixActions>
      <PixTransactions>
        <ReceiptList
          headerLink={"/extrato"}
          itens={transactionsData}
          product={"pix"}
          type="pix"
          hasFilter
          title={"Meu extrato"}
        />
      </PixTransactions>
      <ReturnPixModal
        open={returnPixModal}
        onClose={() => {
          setReturnPixModal(!returnPixModal);
        }}
      />
    </Container>
  );
};
